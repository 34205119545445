import React from 'react';

const FactoryManagementActiveIcon = () => {
  return (
    <div>
      <svg
        width='24'
        height='20'
        viewBox='0 0 24 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.5 16.25H10.125C10.5392 16.25 10.875 15.9142 10.875 15.5C10.875 15.0858 10.5392 14.75 10.125 14.75H7.5C7.08579 14.75 6.75 15.0858 6.75 15.5C6.75 15.9142 7.08579 16.25 7.5 16.25Z'
          fill='white'
        />
        <path
          d='M13.875 16.25H16.5C16.9142 16.25 17.25 15.9142 17.25 15.5C17.25 15.0858 16.9142 14.75 16.5 14.75H13.875C13.4608 14.75 13.125 15.0858 13.125 15.5C13.125 15.9142 13.4608 16.25 13.875 16.25Z'
          fill='white'
        />
        <path
          d='M3 7.25V19.25C3 19.6642 3.33579 20 3.75 20C4.16421 20 4.5 19.6642 4.5 19.25V8.75L9.3 12.35C9.63137 12.5985 10.1015 12.5314 10.35 12.2C10.4474 12.0702 10.5 11.9123 10.5 11.75V8.75L15.3 12.35C15.4298 12.4474 15.5877 12.5 15.75 12.5H19.5V19.25C19.5 19.6642 19.8358 20 20.25 20C20.6642 20 21 19.6642 21 19.25V11.75C21 11.3358 20.6642 11 20.25 11H16L10.2 6.65C10.0702 6.55263 9.91228 6.5 9.75 6.5C9.33579 6.5 9 6.83579 9 7.25V10.25L4.2 6.65C4.07018 6.55263 3.91228 6.5 3.75 6.5C3.33579 6.5 3 6.83579 3 7.25Z'
          fill='white'
        />
        <path
          d='M1.5 20H22.5C22.9142 20 23.25 19.6642 23.25 19.25C23.25 18.8358 22.9142 18.5 22.5 18.5H1.5C1.08579 18.5 0.75 18.8358 0.75 19.25C0.75 19.6642 1.08579 20 1.5 20Z'
          fill='white'
        />
        <path
          d='M13.6482 10.631C13.6432 10.6662 13.6406 10.7019 13.6406 10.7375L13.6406 10.7399C13.6411 10.9012 13.6937 11.0582 13.7905 11.1873C13.9098 11.3465 14.0874 11.4517 14.2843 11.4799C14.3195 11.485 14.3551 11.4875 14.3906 11.4875C14.4008 11.4875 14.411 11.4873 14.4212 11.4869C14.7825 11.4721 15.0818 11.2017 15.1331 10.8438L16.3987 2.00316L16.3991 2L18.1009 2L19.5075 11.856C19.5603 12.2255 19.8767 12.5 20.25 12.5L20.2542 12.5C20.2883 12.4998 20.3223 12.4973 20.356 12.4925C20.7255 12.4397 21 12.1233 21 11.75L21 11.7458C20.9998 11.7117 20.9973 11.6777 20.9925 11.644L19.5866 1.79375C19.5096 1.23881 19.0876 0.870246 19.0876 0.870246C18.6656 0.501686 18.1031 0.5 18.1031 0.5L16.3946 0.500003C15.8344 0.501686 15.4124 0.870246 15.4124 0.870246C14.9904 1.23881 14.9138 1.79059 14.9138 1.79059L13.6482 10.631Z'
          fill='white'
        />
      </svg>
    </div>
  );
};

export default FactoryManagementActiveIcon;
