import React from 'react';

const DashboardInActiveIcon = () => {
  return (
    <div>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.7502 10.4191C19.7411 10.4355 19.7278 10.4507 19.7232 10.4686C19.5206 11.2326 19.0117 11.6276 18.2192 11.628C16.3979 11.6291 14.5762 11.6284 12.7549 11.6284C12.6025 11.6284 12.4502 11.6299 12.2978 11.6284C11.4207 11.6185 10.8166 11.0163 10.8106 10.1396C10.8064 9.53665 10.8098 8.93374 10.8098 8.33083C10.8098 6.21741 10.8098 4.10398 10.8098 1.99056C10.8094 0.960696 11.0688 0.587447 12.0187 0.25H18.5696C18.8982 0.353596 19.2246 0.478139 19.4208 0.776357C19.5598 0.988118 19.6425 1.23682 19.7502 1.46877V10.4191ZM18.5315 5.93861C18.5315 4.55531 18.5315 3.17238 18.5315 1.78908C18.5315 1.51828 18.4816 1.46877 18.2089 1.46839C16.2547 1.46839 14.3004 1.46839 12.3462 1.46839C12.083 1.46839 12.0282 1.52438 12.0282 1.79555C12.0282 4.55569 12.0282 7.31544 12.0282 10.0756C12.0282 10.3528 12.0849 10.4088 12.363 10.4092C14.3107 10.4096 16.2585 10.4096 18.2066 10.4092C18.4747 10.4092 18.5311 10.3528 18.5315 10.0881C18.5315 8.70484 18.5315 7.32192 18.5315 5.93861Z'
          fill='#020202'
        />
        <path
          d='M0.249634 9.58124C0.261441 9.5523 0.276294 9.52373 0.284293 9.49364C0.456063 8.81951 0.972898 8.38037 1.66722 8.37695C3.69799 8.36628 5.72877 8.36438 7.75955 8.37656C8.60507 8.38152 9.18779 9.01147 9.18932 9.88022C9.19236 11.454 9.19008 13.0281 9.19008 14.6018C9.19008 15.7379 9.19008 16.8737 9.19008 18.0098C9.19008 19.0389 8.93338 19.4087 7.98083 19.7504H1.4684C1.01898 19.643 0.646877 19.4243 0.424832 19.0035C0.352468 18.8664 0.307145 18.7148 0.249634 18.5697C0.249634 15.5734 0.249634 12.5775 0.249634 9.58124ZM1.4684 14.0587C1.4684 15.4355 1.4684 16.8124 1.4684 18.1892C1.4684 18.4878 1.51144 18.5316 1.80738 18.5316C3.74903 18.5316 5.6903 18.5316 7.63196 18.5316C7.92256 18.5316 7.97207 18.4821 7.97207 18.1873C7.97207 15.4401 7.97207 12.6929 7.97207 9.94535C7.97207 9.64104 7.92218 9.59038 7.62015 9.59038C5.68497 9.59038 3.74979 9.59038 1.81461 9.59038C1.51297 9.59038 1.4684 9.63685 1.4684 9.94726C1.4684 11.3176 1.4684 12.6883 1.4684 14.0587Z'
          fill='#020202'
        />
        <path
          d='M7.98121 0.25C8.21773 0.365021 8.479 0.446908 8.68505 0.602301C9.0404 0.869669 9.1878 1.26348 9.18894 1.70453C9.19199 2.90387 9.1977 4.10322 9.18741 5.30257C9.18018 6.13819 8.57651 6.74605 7.74012 6.75024C5.72839 6.75938 3.71704 6.75709 1.7053 6.751C0.989276 6.74871 0.460253 6.31986 0.284293 5.63163C0.276675 5.60155 0.261441 5.57336 0.249634 5.54404C0.249634 4.17292 0.249634 2.8018 0.249634 1.43068C0.272867 1.3667 0.298004 1.30309 0.319713 1.23873C0.448827 0.852149 0.694104 0.566119 1.06316 0.392444C1.18161 0.336837 1.30768 0.296847 1.43032 0.25C3.61382 0.25 5.79771 0.25 7.98121 0.25ZM4.71643 5.53451C5.69335 5.53451 6.67027 5.53489 7.64719 5.53451C7.91304 5.53451 7.97169 5.47548 7.97169 5.20964C7.97245 4.0678 7.97245 2.92596 7.97169 1.78413C7.97169 1.52742 7.91304 1.46877 7.66052 1.46877C5.70059 1.46839 3.74027 1.46877 1.78033 1.46877C1.52401 1.46877 1.46879 1.524 1.46879 1.7807C1.4684 2.92254 1.46879 4.06437 1.46879 5.20621C1.46879 5.48234 1.52211 5.53451 1.80509 5.53451C2.77554 5.53489 3.74598 5.53451 4.71681 5.53451H4.71643Z'
          fill='#020202'
        />
        <path
          d='M12.0183 19.7506C11.8824 19.701 11.7395 19.6649 11.6116 19.6001C11.0951 19.3385 10.8201 18.9069 10.814 18.3337C10.8015 17.1089 10.7973 15.884 10.8148 14.6595C10.8266 13.8376 11.4455 13.2534 12.2788 13.2496C13.7383 13.2431 15.1982 13.2477 16.6577 13.2477C17.1779 13.2477 17.6986 13.2465 18.2188 13.2477C19.0122 13.2496 19.5203 13.6426 19.7225 14.4074C19.7271 14.4249 19.7404 14.4402 19.7495 14.4569V18.5322C19.6497 18.7538 19.5759 18.9919 19.4448 19.1934C19.2346 19.5163 18.8941 19.6607 18.5308 19.7509H12.018L12.0183 19.7506ZM15.2774 18.5318C16.2543 18.5318 17.2312 18.5318 18.2082 18.5318C18.4805 18.5318 18.5308 18.4815 18.5311 18.2115C18.5311 17.0696 18.5311 15.9278 18.5311 14.786C18.5311 14.5217 18.4748 14.466 18.2055 14.466C16.2581 14.4657 14.3108 14.4657 12.3634 14.466C12.0846 14.466 12.0282 14.522 12.0282 14.7985C12.0279 15.9339 12.0275 17.0693 12.0282 18.205C12.0282 18.4804 12.0823 18.5318 12.3661 18.5318C13.3365 18.5318 14.307 18.5318 15.2778 18.5318H15.2774Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default DashboardInActiveIcon;
