const CheckboxFilledIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.39316 11.8328C8.25362 11.6994 8.06802 11.625 7.875 11.625C7.87484 11.625 7.85799 11.6252 7.85799 11.6252C7.65913 11.6297 7.4702 11.713 7.33278 11.8568C7.19942 11.9964 7.125 12.182 7.125 12.375L7.12519 12.392C7.1297 12.5909 7.21303 12.7798 7.35684 12.9172L10.1037 15.5422C10.3934 15.8191 10.8496 15.8193 11.1396 15.5427L16.6425 10.2928C16.7864 10.1555 16.8701 9.96651 16.8748 9.76765C16.8751 9.75564 16.8751 9.74362 16.8748 9.73161C16.8702 9.54522 16.7964 9.36721 16.6677 9.2323C16.5261 9.08394 16.33 9 16.125 9L16.1038 9.0003C15.9184 9.00553 15.7415 9.0793 15.6073 9.20734L10.6223 13.963L8.39316 11.8328Z'
        fill='#0160B9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 4.5C3 4.5 3 3.87868 3.43934 3.43934C3.43934 3.43934 3.87868 3 4.5 3H19.5C19.5 3 20.1213 3 20.5607 3.43934C20.5607 3.43934 21 3.87868 21 4.5V19.5C21 19.5 21 20.1213 20.5607 20.5607C20.5607 20.5607 20.1213 21 19.5 21H4.5C4.5 21 3.87868 21 3.43934 20.5607C3.43934 20.5607 3 20.1213 3 19.5V4.5ZM4.5 4.5V19.5H19.5V4.5H4.5Z'
        fill='#0160B9'
      />
    </svg>
  );
};

export default CheckboxFilledIcon;
