import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { authApi, verifyToken } from '../api/auth';
import { cookie } from '../Utils/Cookies/cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { user } from '../queryKeys/userManagement';

export const useLogin = (handleOpen?: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(authApi.login, {
    onSuccess: (res) => {
      const data = res.data;
      if (data.tokens) {
        queryClient.setQueryData([user.LOGGED_IN_USER], data.user);
        // toast.success(data.message);
        cookie.set('token', data.tokens.access.token);
        navigate('/dashboard');
      }
    },

    onError: (err: any, prev, context) => {
      const errData = err?.data;
      // queryClient.invalidateQueries(['user']);
      handleOpen();
      // navigate('/login');
      //toast.error(errData.message);
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(authApi.logout, {
    onSuccess: () => {
      cookie.remove('token');
      queryClient.removeQueries();
      navigate('/login');
    },
  });
};

export const useChangePassword = (handleOpen?: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(authApi.changePassword, {
    onSuccess: (res) => {
      const data = res.data;
      if (data.tokens) {
        // toast.success(data.message);
      }
    },
    onError: (err: any, prev, context) => {
      const errData = err?.data;
      // queryClient.invalidateQueries([user.LOGGED_IN_USER]);
      handleOpen();
      // navigate('/login');
      //toast.error(errData.message);
    },
  });
};
export const useResetPassword = (handleOpen?: any) => {
  const navigate = useNavigate();

  return useMutation(authApi.resetPassword, {
    onSuccess: (res) => {
      const data = res.data;
      navigate('/login');
    },
    onError: (err: any) => {
      const errData = err?.data;
      handleOpen();
    },
  });
};

export const useForgotPassword = (handleOpen?: any, handleSuccessOpen?: any) => {
  // const navigate = useNavigate();
  // const queryClient = useQueryClient();

  return useMutation(authApi.forgotPassword, {
    onSuccess: (res) => {
      const data = res.data;
      // navigate('/login');
      handleSuccessOpen();
    },
    onError: (err: any) => {
      const errData = err?.data;
      handleOpen();
      // queryClient.invalidateQueries([user.LOGGED_IN_USER]);
      //toast.error(errData.message);
    },
  });
};

export const useVerifyToken = () => {
  const navigate = useNavigate();
  return useQuery([user.LOGGED_IN_USER], verifyToken, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: () => {
      cookie.clean();
      navigate('/login');
    },
  });
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(authApi.updateProfile, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([user.LOGGED_IN_USER]);
      // queryClient.invalidateQueries([user.LOGGED_IN_USER, id]);
      const data = res.data;
      // toast.success(data?.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
