import React from 'react';

const CommentIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3022 16.9053C13.3022 16.9053 11.8665 17.5 10.3125 17.5L3.73483 17.4999C3.73483 17.4999 3.48823 17.5032 3.25976 17.4103C3.25976 17.4103 3.03129 17.3175 2.8569 17.1431C2.8569 17.1431 2.68251 16.9687 2.58965 16.7402C2.58965 16.7402 2.49678 16.5118 2.49999 16.2734L2.49999 9.6875C2.49999 9.6875 2.49999 8.1335 3.09468 6.69779C3.09468 6.69779 3.68937 5.26207 4.78822 4.16323C4.78822 4.16323 5.88706 3.06438 7.32278 2.46969C7.32278 2.46969 8.75849 1.875 10.3125 1.875C10.3125 1.875 11.8665 1.875 13.3022 2.46969C13.3022 2.46969 14.7379 3.06438 15.8368 4.16323C15.8368 4.16323 16.9356 5.26208 17.5303 6.69779C17.5303 6.69779 18.125 8.1335 18.125 9.6875V9.68923C18.125 9.68923 18.1247 11.2423 17.5303 12.6772C17.5303 12.6772 16.9356 14.1129 15.8368 15.2118C15.8368 15.2118 14.7379 16.3106 13.3022 16.9053ZM10.3125 3.125C10.3125 3.125 9.00713 3.125 7.80113 3.62454C7.80113 3.62454 6.59513 4.12408 5.6721 5.04711C5.6721 5.04711 4.74907 5.97014 4.24953 7.17614C4.24953 7.17614 3.74999 8.38214 3.74999 9.6875L3.74999 16.2501L10.3125 16.25C10.3125 16.25 11.6178 16.25 12.8239 15.7505C12.8239 15.7505 14.0298 15.2509 14.9529 14.3279C14.9529 14.3279 15.8759 13.4049 16.3754 12.1989C16.3754 12.1989 16.875 10.9929 16.875 9.6875C16.875 9.6875 16.875 8.38214 16.3754 7.17614C16.3754 7.17614 15.8759 5.97015 14.9529 5.04711C14.9529 5.04711 14.0299 4.12408 12.8238 3.62454C12.8238 3.62454 11.6177 3.12504 10.3125 3.125Z'
        fill='#0160B9'
      />
      <path
        d='M7.8125 9.375H12.5C12.8452 9.375 13.125 9.09518 13.125 8.75C13.125 8.40482 12.8452 8.125 12.5 8.125H7.8125C7.46732 8.125 7.1875 8.40482 7.1875 8.75C7.1875 9.09518 7.46732 9.375 7.8125 9.375Z'
        fill='#0160B9'
      />
      <path
        d='M7.8125 11.875H12.5C12.8452 11.875 13.125 11.5952 13.125 11.25C13.125 10.9048 12.8452 10.625 12.5 10.625H7.8125C7.46732 10.625 7.1875 10.9048 7.1875 11.25C7.1875 11.5952 7.46732 11.875 7.8125 11.875Z'
        fill='#0160B9'
      />
    </svg>
  );
};

export default CommentIcon;
