import React from 'react';

const CustomizeColumn = () => {
  return (
    <React.Fragment>
      <svg
        width='18'
        height='14'
        viewBox='0 0 18 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.5 5.125V13.25C6.5 13.5952 6.77982 13.875 7.125 13.875C7.47018 13.875 7.75 13.5952 7.75 13.25V5.125C7.75 4.77982 7.47018 4.5 7.125 4.5C6.77982 4.5 6.5 4.77982 6.5 5.125Z'
          fill='#0160B9'
        />
        <path
          d='M1.5 5.75H16.5C16.8452 5.75 17.125 5.47018 17.125 5.125C17.125 4.77982 16.8452 4.5 16.5 4.5H1.5C1.15482 4.5 0.875 4.77982 0.875 5.125C0.875 5.47018 1.15482 5.75 1.5 5.75Z'
          fill='#0160B9'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.875 1.375C0.875 1.375 0.875 0.857233 1.24112 0.491117C1.24112 0.491117 1.60723 0.125 2.125 0.125H15.875C15.875 0.125 16.3928 0.125 16.7589 0.491117C16.7589 0.491117 17.125 0.857232 17.125 1.375V12.625C17.125 12.625 17.125 13.1428 16.7589 13.5089C16.7589 13.5089 16.3928 13.875 15.875 13.875H2.125C2.125 13.875 1.60723 13.875 1.24112 13.5089C1.24112 13.5089 0.875 13.1428 0.875 12.625V1.375ZM2.125 1.375V12.625H15.875V1.375H2.125Z'
          fill='#0160B9'
        />
      </svg>
    </React.Fragment>
  );
};

export default CustomizeColumn;
