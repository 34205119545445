import React from 'react';

const WorkFlow = () => {
  return (
    <svg
      width='200'
      height='32'
      viewBox='0 0 200 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42.0552 2.69876L33.8199 32.1772H26.8515L21.319 11.1875L15.5331 32.1772L8.60693 32.2194L0.667175 2.69876H7.00208L12.1967 25.5889L18.1938 2.69876H24.7821L30.4412 25.4622L35.6781 2.69876H42.0552Z'
        fill='white'
      />
      <path
        d='M56.3052 32.5573C54.0527 32.5573 52.0256 32.0646 50.2236 31.0792C48.4217 30.0656 46.9999 28.6437 45.9581 26.8136C44.9446 24.9836 44.4378 22.8719 44.4378 20.4787C44.4378 18.0855 44.9586 15.9739 46.0004 14.1438C47.0703 12.3137 48.5203 10.906 50.3503 9.92056C52.1804 8.90697 54.2217 8.40018 56.4741 8.40018C58.7265 8.40018 60.7677 8.90697 62.5978 9.92056C64.4279 10.906 65.8638 12.3137 66.9056 14.1438C67.9755 15.9739 68.5104 18.0855 68.5104 20.4787C68.5104 22.8719 67.9614 24.9836 66.8633 26.8136C65.7934 28.6437 64.3294 30.0656 62.4711 31.0792C60.641 32.0646 58.5857 32.5573 56.3052 32.5573ZM56.3052 27.4049C57.3751 27.4049 58.3746 27.1515 59.3037 26.6447C60.261 26.1098 61.0211 25.3214 61.5842 24.2797C62.1474 23.2379 62.4289 21.971 62.4289 20.4787C62.4289 18.2545 61.8376 16.5511 60.6551 15.3686C59.5008 14.1579 58.0789 13.5526 56.3896 13.5526C54.7003 13.5526 53.2785 14.1579 52.1241 15.3686C50.9979 16.5511 50.4348 18.2545 50.4348 20.4787C50.4348 22.703 50.9838 24.4205 52.0819 25.6311C53.2081 26.8136 54.6158 27.4049 56.3052 27.4049Z'
        fill='white'
      />
      <path
        d='M78.7601 12.4123C79.5203 11.1735 80.5057 10.2021 81.7164 9.49823C82.9552 8.79435 84.363 8.44241 85.9397 8.44241V14.6506H84.3771C82.5188 14.6506 81.111 15.087 80.1538 15.9598C79.2247 16.8326 78.7601 18.353 78.7601 20.521V32.1772H72.8475V8.78027H78.7601V12.4123Z'
        fill='white'
      />
      <path
        d='M103.774 32.1772L95.8346 22.2103V32.1772H89.9221V0.924988H95.8346V18.705L103.69 8.78027H111.376L101.072 20.521L111.461 32.1772H103.774Z'
        fill='white'
      />
      <path
        d='M130.549 3.03662V5.44389H117.795V16.3399H127.973V18.705H117.795V32.1772H114.839V3.03662H130.549Z'
        fill='white'
      />
      <path d='M137.55 0.924988V32.1772H134.594V0.924988H137.55Z' fill='white' />
      <path
        d='M153.925 8.69581C156.093 8.69581 158.05 9.2026 159.795 10.2162C161.541 11.2016 162.921 12.5953 163.934 14.3972C164.948 16.1992 165.455 18.2826 165.455 20.6477C165.455 22.9845 164.92 25.068 163.85 26.8981C162.78 28.7 161.33 30.1078 159.5 31.1214C157.67 32.1068 155.671 32.5995 153.503 32.5995C151.363 32.5995 149.448 32.1068 147.759 31.1214C146.07 30.1078 144.747 28.7 143.789 26.8981C142.86 25.0962 142.396 23.0127 142.396 20.6477C142.396 18.2826 142.902 16.1992 143.916 14.3972C144.93 12.5953 146.309 11.2016 148.055 10.2162C149.8 9.2026 151.757 8.69581 153.925 8.69581ZM153.756 11.0608C152.208 11.0608 150.8 11.4409 149.533 12.2011C148.266 12.9332 147.252 14.0171 146.492 15.453C145.76 16.889 145.394 18.6205 145.394 20.6477C145.394 22.6467 145.746 24.3782 146.45 25.8423C147.154 27.2782 148.111 28.3763 149.322 29.1364C150.561 29.8685 151.954 30.2345 153.503 30.2345C155.051 30.2345 156.501 29.8544 157.853 29.0942C159.204 28.334 160.288 27.236 161.105 25.8001C161.949 24.3641 162.372 22.6467 162.372 20.6477C162.372 18.6487 161.977 16.9312 161.189 15.4953C160.401 14.0312 159.345 12.9332 158.022 12.2011C156.727 11.4409 155.305 11.0608 153.756 11.0608Z'
        fill='white'
      />
      <path
        d='M200 9.11814L192.652 32.1772H189.653L183.909 12.7502L178.166 32.1772H175.167L167.734 9.11814H170.859L176.645 29.0097L182.6 9.11814H185.556L191.131 28.9675L196.875 9.11814H200Z'
        fill='white'
      />
    </svg>
  );
};

export default WorkFlow;
