import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from '../queryKeys/notification';
import { notificationApi } from '../api/notification';

export const useGetNotifications = (isEnable = true, days = '') => {
  return useQuery(
    [notification.NOTIFICATIONS, days ? days : ''],
    notificationApi.getUserNotifications,
    {
      enabled: isEnable,
      select: (data: any) => data?.data?.notifications,
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(notificationApi.readNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries([notification.NOTIFICATIONS]);
    },
  });
};
