import React from 'react';

const CatalogueInActive = () => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.3484 19.0983C11.25 20.1967 11.25 21.75 11.25 21.75C11.25 22.1642 11.5858 22.5 12 22.5C12.4142 22.5 12.75 22.1642 12.75 21.75C12.75 20.818 13.409 20.159 13.409 20.159C14.068 19.5 15 19.5 15 19.5H21C21.6213 19.5 22.0607 19.0607 22.0607 19.0607C22.5 18.6213 22.5 18 22.5 18V6C22.5 5.37868 22.0607 4.93934 22.0607 4.93934C21.6213 4.5 21 4.5 21 4.5H15C13.4467 4.5 12.3484 5.59835 12.3484 5.59835C11.25 6.6967 11.25 8.25 11.25 8.25C11.25 8.66421 11.5858 9 12 9C12.1989 9 12.3897 8.92098 12.5303 8.78033C12.671 8.63968 12.75 8.44891 12.75 8.25C12.75 7.31802 13.409 6.65901 13.409 6.65901C14.068 6 15 6 15 6H21V18H15C13.4467 18 12.3484 19.0983 12.3484 19.0983Z'
          fill='#020202'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.5 6C1.5 6 1.5 5.37868 1.93934 4.93934C1.93934 4.93934 2.37868 4.5 3 4.5H9C9 4.5 10.5533 4.5 11.6517 5.59835C11.6517 5.59835 12.75 6.6967 12.75 8.25V21.75C12.75 22.1642 12.4142 22.5 12 22.5C11.5858 22.5 11.25 22.1642 11.25 21.75C11.25 21.75 11.25 20.818 10.591 20.159C10.591 20.159 9.93198 19.5 9 19.5H3C3 19.5 2.37868 19.5 1.93934 19.0607C1.93934 19.0607 1.5 18.6213 1.5 18V6ZM11.25 8.25V18.745C11.25 18.745 10.2793 18 9 18H3V6H9C9 6 9.93198 6 10.591 6.65901C10.591 6.65901 11.25 7.31802 11.25 8.25Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default CatalogueInActive;
