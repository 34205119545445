import React from 'react';

const ResourceInActive = () => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.75 18.75V9.75C3.75 9.33579 3.41421 9 3 9C2.58579 9 2.25 9.33579 2.25 9.75V18.75C2.25 19.3713 2.68934 19.8107 2.68934 19.8107C3.12868 20.25 3.75 20.25 3.75 20.25H20.25C20.8713 20.25 21.3107 19.8107 21.3107 19.8107C21.75 19.3713 21.75 18.75 21.75 18.75V8.25C21.75 7.62868 21.3107 7.18934 21.3107 7.18934C20.8713 6.75 20.25 6.75 20.25 6.75H12C11.5858 6.75 11.25 7.08579 11.25 7.5C11.25 7.91421 11.5858 8.25 12 8.25H20.25V18.75H3.75Z'
          fill='#020202'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.6444 10.2018C9.6444 10.2018 9.24649 10.4977 8.7507 10.5L8.74688 10.5H3C2.58579 10.5 2.25 10.1642 2.25 9.75V6C2.25 6 2.25 5.37868 2.68934 4.93934C2.68934 4.93934 3.12868 4.5 3.75 4.5L8.74996 4.50001C8.74996 4.50001 9.24619 4.50205 9.64655 4.79976L12.4497 6.89976C12.5068 6.94253 12.5575 6.99323 12.6002 7.05032C12.7195 7.20952 12.7706 7.40957 12.7424 7.60646C12.7142 7.80336 12.6089 7.98098 12.4497 8.10024L9.6444 10.2018ZM8.74411 9C8.74411 9 8.747 8.9999 8.74935 8.99815L10.7491 7.5L8.7472 6.00024C8.7472 6.00024 8.74687 6.00001 8.74379 5.99999L3.75 6V9H8.74411Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default ResourceInActive;
