import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useParams } from 'react-router-dom';
import { useDeleteChecklist, useGetChecklist } from '../../hooks/checklistHooks';
import _ from 'lodash';
import './../../assets/css/detailsInformationBox.css';
const TextFiled = styled(Typography)(() => ({
  fontSize: '14px !important',
  padding: '24px 24px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
}));

const ChecklistDetailsView = () => {
  const { id } = useParams();
  const { data: getChecklist } = useGetChecklist(id);
  const checklist = getChecklist?.checklist;
  const { mutate: deleteChecklist } = useDeleteChecklist();
  const handleDelete = () => {
    deleteChecklist(id);
  };

  const breadCrumbData = [
    {
      title: 'Checklist',
      link: '/checklists',
    },
    {
      title: _.get(checklist, 'title', '-'),
      link: `/checklist-details-view/${id}`,
    },
  ];

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-checklist/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(checklist, 'title', '-')}
        backToLink='/checklists'
        isDetailPage={true}
        detailPageData={detailPageData}
        handleDelete={handleDelete}
      />
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container>
            <Grid item xs={8}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Description
                </Typography>
                <TextFiled>{_.get(checklist, 'description', '')}</TextFiled>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box>
          <Typography id='modal-modal-title' className='checklist-text'>
            Checklist Items
          </Typography>
          <Box>
            <Grid container className='process-header'>
              <Grid item xs={1} textAlign={'center'}>
                <Typography className='process-header-field-title'>#</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className='process-header-field-title'>Checklist Item</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className='process-header-field-title'>Response Type</Typography>
              </Grid>
            </Grid>
          </Box>
          {checklist?.checklistItems?.map((data, ind) => {
            return (
              <Grid container key={ind} className='border-items'>
                <Grid item xs={1}>
                  <Box textAlign={'center'}>
                    <Typography className='field-title'>{ind + 1}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Box>
                    <Typography className='field-title'>{_.get(data, 'name', '')}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Typography className='field-title'>
                      {_.get(data, 'responseType', '')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </HeadBoxWrapper>
    </React.Fragment>
  );
};
export default ChecklistDetailsView;
