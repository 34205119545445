import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { userManagementApi } from '../api/userManagement';
import { user } from '../queryKeys/userManagement';
import { userErrI } from '../Types/userManagement';

export const useGetDesktopUser = (id, isEnable = true) => {
  return useQuery([user.DESKTOP_USER, id], userManagementApi.getSingleDesktopUser, {
    enabled: isEnable,
    select: (data) => data,
    // onSuccess: (res: any) => {
    //   const data = res;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetUserRoles = (isEnabled: boolean) => {
  return useQuery([user.USER_ROLES], userManagementApi.getDesktopUserRoles, {
    enabled: isEnabled,
    select: (data) => data.data?.userRoles?.results,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetDesktopUsers = (isEnabled: boolean, page) => {
  return useQuery([user.DESKTOP_USERS, page], userManagementApi.getDesktopUser, {
    initialData: { users: { results: [] } },
    enabled: isEnabled,
    select: (data) => data?.users,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullGetDesktopUsers = (isEnabled: boolean) => {
  return useQuery([user.DESKTOP_USERS + 'FULL'], userManagementApi.getFullDesktopUser, {
    enabled: isEnabled,
    select: (data) => data?.users,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetAllUsers = (isEnabled: boolean) => {
  return useQuery([user.DESKTOP_USERS + 'ALL'], userManagementApi.getAllUsers, {
    enabled: isEnabled,
    select: (data) => data?.users,
    // onSuccess: (res: any) => {
    //   const data = res;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddDesktopUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(userManagementApi.addDesktopUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([user.DESKTOP_USERS]);
      const data = res;
      // toast.success(data.message);
      // navigate(`/desktop-user-details/${data?.user?.id}`);
      navigate(`/user-management`, { state: { activeTab: '1' } });
    },
    onError: (err: userErrI) => {
      return err;
      // toast.error(errData.message);
    },
  });
};

export const useUpdateDesktopUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(userManagementApi.updateDesktopUser, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([user.DESKTOP_USERS]);
      queryClient.invalidateQueries([user.DESKTOP_USER, id]);
      const data = res;
      // toast.success(data.message);
      // navigate(`/desktop-user-details/${data?.user?.id}`);
      navigate(`/user-management`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      return err;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteDesktopUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(userManagementApi.deleteDesktopUser, {
    onSuccess: (res) => {
      const data = res.data;
      queryClient.invalidateQueries([user.DESKTOP_USERS]);
      queryClient.removeQueries([user.DESKTOP_USER, data?.user?.id]);
      // toast.success(data.message);
      navigate(`/user-management`, { state: { activeTab: '1' } });
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};
export const useGetDeletedDesktopUser = (id, isEnable = true) => {
  return useQuery(
    [user.DESKTOP_USER + 'DELETED', id],
    userManagementApi.getSingleDeletedDesktopUser,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};
export const useRestoreDesktopUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(userManagementApi.restoreDesktopUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.DESKTOP_USERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeleteDesktopUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(userManagementApi.hardDeleteDesktopUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.DESKTOP_USERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};
