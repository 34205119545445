import React from 'react';
interface PropI {
  fill: string;
}
const Plus = (props: PropI) => {
  const { fill } = props;
  return (
    <React.Fragment>
      <svg
        width='15'
        height='15'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.125 8.625H14.875C15.2202 8.625 15.5 8.34518 15.5 8C15.5 7.65482 15.2202 7.375 14.875 7.375H1.125C0.779822 7.375 0.5 7.65482 0.5 8C0.5 8.34518 0.779822 8.625 1.125 8.625Z'
          fill={fill}
        />
        <path
          d='M7.375 1.125V14.875C7.375 15.2202 7.65482 15.5 8 15.5C8.34518 15.5 8.625 15.2202 8.625 14.875V1.125C8.625 0.779822 8.34518 0.5 8 0.5C7.65482 0.5 7.375 0.779822 7.375 1.125Z'
          fill={fill}
        />
      </svg>
    </React.Fragment>
  );
};

export default Plus;
