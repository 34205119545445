import { timeData } from './getTimeData';

//Old Functionality
// const getStartTimeOld = (startTime) => {
//   const startHour = startTime?.split(':')?.[0];
//   const startMinute = startTime?.split(':')?.[1];
//   return `${[
//     parseInt(startHour) === 0
//       ? '12'
//       : parseInt(startHour) > 12
//       ? parseInt(startHour) > 12 && parseInt(startHour) < 22
//         ? '0' + (parseInt(startHour) - 12)
//         : parseInt(startHour) - 12
//       : `${parseInt(startHour) < 10 ? '0' + parseInt(startHour) : parseInt(startHour)}`,
//     parseInt(startMinute) === 0 ? '00' : parseInt(startMinute),
//   ].join(':')} ${parseInt(startHour) > 12 ? 'PM' : 'AM'}`;
// };

const getStartTime = (time) => {
  const res = timeData?.find((data) => data?.value === time);
  return res?.label;
};

//Old Functionality
// const getEndTimeOld = (endTime) => {
//   const endHour = endTime?.split(':')?.[0];
//   const endMinute = endTime?.split(':')?.[1];
//   return `${[
//     parseInt(endHour) === 0
//       ? '12'
//       : parseInt(endHour) > 12
//       ? parseInt(endHour) > 12 && parseInt(endHour) < 22
//         ? '0' + (parseInt(endHour) - 12)
//         : parseInt(endHour) - 12
//       : `${parseInt(endHour) < 10 ? '0' + parseInt(endHour) : parseInt(endHour)}`,
//     parseInt(endMinute) === 0 ? '00' : parseInt(endMinute),
//   ].join(':')} ${parseInt(endHour) > 12 ? 'PM' : 'AM'}`;
// };

const getEndTime = (time) => {
  const res = timeData?.find((data) => data?.value === time);
  return res?.label;
};

export { getStartTime, getEndTime };
