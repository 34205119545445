import React from 'react';
import { Outlet } from 'react-router-dom';
import BreadcrumbNavigation from '../../commonComponents/BreadCrumbNavigation';

const DuplicateProcessWrapper = () => {
  const breadCrumbData = [
    {
      title: 'process',
      link: '/processes',
    },
    {
      title: `Add Variant`,
      link: `/process/add-variant/select-process`,
    },
  ];
  return (
    <>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Add Variant'}
        backToLink='/processes'
      />
      <Outlet />
    </>
  );
};

export default DuplicateProcessWrapper;
