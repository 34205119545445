import * as React from 'react';
import styled from '@emotion/styled';
import { Grid, Typography, Select, Box } from '@mui/material';
import { MenuItem, FormControl } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import RecentActivityCard from './RecentActivityCard';
import { useGetNotifications } from '../../hooks/notificationHooks';

const Selects = styled(Select)(() => ({
  borderRadius: '10px !important',
  '.MuiOutlinedInput-input': {
    padding: '13.5px 14px',
  },
}));

const RecentActivity = () => {
  const [days, setDays] = React.useState<any>('7');
  const breadCrumbData = [
    {
      title: 'Dashboard',
      link: '/dashboard',
    },
    {
      title: 'Recent Activity',
      link: ``,
    },
  ];

  const { data: notificationList } = useGetNotifications(true, days);

  return (
    <>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle='Recent Activity'
        backToLink='/dashboard'
      />
      <Box className='main-padding'>
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={4}>
            <Typography className='field-title'>Time</Typography>
            <FormControl fullWidth>
              <Selects
                onChange={(e) => setDays(e.target.value)}
                name='ticketOrigin'
                variant='outlined'
                defaultValue='7'
                displayEmpty
              >
                <MenuItem value='' disabled>
                  <span className='menu-item-span'>Choose Time Frame</span>
                </MenuItem>
                <MenuItem value='7'>Last 7 days</MenuItem>
                <MenuItem value='30'>Last 30 days</MenuItem>
              </Selects>
            </FormControl>
          </Grid>
        </Grid>
        {notificationList?.map((notificationDetails, ind) => {
          return <RecentActivityCard key={ind} notificationData={notificationDetails} />;
        })}
      </Box>
    </>
  );
};

export default RecentActivity;
