import React from 'react';

const RestoreIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.10938 7.16427V4.03927C3.10938 3.69409 2.82955 3.41427 2.48438 3.41427C2.1392 3.41427 1.85938 3.69409 1.85938 4.03927V7.78927C1.85938 8.13445 2.1392 8.41427 2.48438 8.41427H6.23438C6.57955 8.41427 6.85938 8.13445 6.85938 7.78927C6.85938 7.44409 6.57955 7.16427 6.23438 7.16427H3.10938Z'
        fill='#0160B9'
      />
      <path
        d='M4.69933 4.69827L2.04308 7.34671C1.92548 7.46397 1.85938 7.62319 1.85938 7.78927C1.85938 7.79975 1.85964 7.81026 1.86017 7.82072C1.86794 7.9751 1.93264 8.12113 2.04178 8.23059C2.15905 8.3482 2.3183 8.41427 2.48438 8.41427C2.49485 8.41427 2.50533 8.41404 2.51579 8.41351C2.67018 8.40574 2.8162 8.34104 2.92567 8.23189L5.58192 5.58346C6.92306 4.24122 8.78278 3.87071 8.78278 3.87071C10.6425 3.50019 12.3946 4.2255 12.3946 4.2255C14.1466 4.95082 15.2003 6.5274 15.2003 6.5274C16.254 8.10398 16.254 10.0002 16.254 10.0002C16.254 11.8965 15.2003 13.4731 15.2003 13.4731C14.1466 15.0497 12.3946 15.775 12.3946 15.775C10.6425 16.5003 8.78278 16.1298 8.78278 16.1298C6.92306 15.7593 5.58275 14.4179 5.58275 14.4179C5.46552 14.3005 5.30648 14.2346 5.14063 14.2346C5.12993 14.2346 5.11923 14.2349 5.10854 14.2354C4.95417 14.2434 4.80821 14.3082 4.69886 14.4175C4.58161 14.5347 4.51569 14.6936 4.51562 14.8594C4.51562 14.8693 4.51586 14.8795 4.51633 14.8894C4.52373 15.0446 4.58868 15.1915 4.6985 15.3014C6.30682 16.9109 8.53854 17.3557 8.53854 17.3557C10.7702 17.8003 12.8727 16.9299 12.8727 16.9299C14.9752 16.0595 16.2396 14.1677 16.2396 14.1677C17.504 12.2758 17.504 10.0002 17.504 10.0002C17.504 7.72472 16.2396 5.83283 16.2396 5.83283C14.9752 3.94093 12.8727 3.07056 12.8727 3.07056C10.7702 2.20018 8.53854 2.6448 8.53854 2.6448C6.30688 3.08941 4.69933 4.69827 4.69933 4.69827Z'
        fill='#0160B9'
      />
    </svg>
  );
};

export default RestoreIcon;
