import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checklistApi } from '../api/checklist';
import { checklist } from '../queryKeys/checklist';

export const useAddChecklist = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(checklistApi.addChecklist, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([checklist.CHECKLISTS]);
      const data = res.data;

      // toast.success(data.message);
      // navigate(`/checklist-details-view/${data?.checklist?.id}`);
      navigate(`/checklists`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useGetChecklist = (id: any, isEnable = true) => {
  return useQuery([checklist.CHECKLIST, id], checklistApi.getSingleChecklist, {
    select: (data) => data.data,
    enabled: isEnable,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetChecklists = (isEnable, page) => {
  return useQuery([checklist.CHECKLISTS, page], checklistApi.getChecklists, {
    enabled: isEnable,
    select: (data) => data?.data?.checklists,
    onSuccess: (res) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useFullGetChecklists = (isEnable) => {
  return useQuery([checklist.CHECKLISTS + 'FULL'], checklistApi.getFullChecklists, {
    enabled: isEnable,
    select: (data) => data?.data?.checklists,
    onSuccess: (res) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteChecklist = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(checklistApi.deleteChecklist, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([checklist.CHECKLISTS]);
      queryClient.invalidateQueries([checklist.CHECKLIST, id]);
      // toast.success(data?.message);
      navigate(`/checklists`);
    },
    onError: (err: any) => {
      const errData = err.data;
      // toast.error(errData?.message);
    },
  });
};

export const useUpdateChecklist = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(checklistApi.updateChecklist, {
    onSuccess: (res, id) => {
      const data = res?.data;
      queryClient.invalidateQueries([checklist.CHECKLISTS]);
      queryClient.invalidateQueries([checklist.CHECKLIST, id]);
      // toast.success(data?.message);
      // navigate(`/checklist-details-view/${data?.checklist?.id}`);
      navigate(`/checklists`);
    },
    onError: (err: any) => {
      const errData = err.data;
      // toast.error(errData?.message);
    },
  });
};
