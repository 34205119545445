import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { lineApi } from '../api/line';
import { line } from '../queryKeys/line';
import { LineErrI } from '../Types/line';

export const useAddLine = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(lineApi.addLine, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([line.LINES]);
      // navigate(`/line-details/${res?.line?.id}`);
      navigate(`/factory-layout`, { state: { activeTab: '1' } });
    },
    onError: (err: LineErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useGetLine = (id, isEnable = true) => {
  return useQuery([line.LINE, id], lineApi.getSingleLine, {
    select: (data) => data?.line,
    enabled: isEnable,
    onSuccess: () => {
      // const data = res?.data;
    },
    onError: () => {
      // const errData = err.data;
      // toast.error(errData?.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetLines = (isEnabled = true, page) => {
  return useQuery([line.LINES, page], lineApi.getLines, {
    initialData: { lines: { results: [] } },
    enabled: isEnabled,
    select: (data) => data?.lines,
    // onSuccess: () => {
    //   // const data = res?.data;
    //   // toast.success(data?.message);
    // },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetDeletedLine = (id, isEnable = true) => {
  return useQuery([line.LINE + 'DELETED', id], lineApi.getSingleDeletedLine, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useFullGetLines = (isEnabled = true) => {
  return useQuery([line.LINES + 'FULL'], lineApi.getFullLines, {
    enabled: isEnabled,
    select: (data) => data?.data?.lines,
    // onSuccess: () => {
    //   // const data = res?.data;
    //   // toast.success(data?.message);
    // },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteLine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(lineApi.deleteLine, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([line.LINES]);
      queryClient.invalidateQueries([line.LINE, id]);
      // toast.success(data?.message);
      navigate(`/factory-layout`, { state: { activeTab: '1' } });
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useRestoreLine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(lineApi.restoreLine, {
    onSuccess: () => {
      queryClient.invalidateQueries([line.LINES]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeleteLine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(lineApi.hardDeleteLine, {
    onSuccess: () => {
      queryClient.invalidateQueries([line.LINES]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};
export const useUpdateLine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(lineApi.updateLine, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([line.LINES]);
      queryClient.invalidateQueries([line.LINE, id]);
      // toast.success(data?.message);
      // navigate(`/line-details/${data?.line?.id}`);
      navigate(`/factory-layout`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err.data;
      // toast.error(errData?.message);
    },
  });
};
