import React from 'react';

const FileUploadIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.33301 11.3346L1.33301 12.168C1.33301 13.5487 2.4523 14.668 3.83301 14.668L12.1663 14.668C13.5471 14.668 14.6663 13.5487 14.6663 12.168L14.6663 11.3346M11.333 4.66797L7.99967 1.33464M7.99967 1.33464L4.66634 4.66797M7.99967 1.33464L7.99967 11.3346'
        stroke='#265AA8'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default FileUploadIcon;
