import React from 'react';

const WorkOrderInActive = () => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 9.75H15C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58579 15.4142 8.25 15 8.25H9C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z'
          fill='#020202'
        />
        <path
          d='M9 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75Z'
          fill='#020202'
        />
        <path
          d='M9 15.75H12C12.4142 15.75 12.75 15.4142 12.75 15C12.75 14.5858 12.4142 14.25 12 14.25H9C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75Z'
          fill='#020202'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.5 21H14.6894L14.6906 21C14.6906 21 14.9861 21.0005 15.2595 20.8882C15.2595 20.8882 15.5342 20.7754 15.7446 20.566L20.5647 15.746C20.5647 15.746 20.7754 15.5342 20.8882 15.2595C20.8882 15.2595 21.001 14.9849 21 14.688L21 4.5C21 4.5 21 3.87868 20.5607 3.43934C20.5607 3.43934 20.1213 3 19.5 3H4.5C4.5 3 3.87868 3 3.43934 3.43934C3.43934 3.43934 3 3.87868 3 4.5V19.5C3 19.5 3 20.1213 3.43934 20.5607C3.43934 20.5607 3.87868 21 4.5 21ZM19.5 14.6893L14.6893 19.5H4.5V4.5H19.5L19.5 14.6893Z'
          fill='#020202'
        />
        <path
          d='M14.25 15V20.1844C14.25 20.5986 14.5858 20.9344 15 20.9344C15.4142 20.9344 15.75 20.5986 15.75 20.1844V15.75H20.1844C20.5986 15.75 20.9344 15.4142 20.9344 15C20.9344 14.5858 20.5986 14.25 20.1844 14.25H15C14.5858 14.25 14.25 14.5858 14.25 15Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default WorkOrderInActive;
