import { Box } from '@mui/material';
import React from 'react';

const RadioButton = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0.25C10 0.25 11.9831 0.25 13.7954 1.01651C13.7954 1.01651 15.5452 1.75663 16.8943 3.10571C16.8943 3.10571 18.2434 4.45478 18.9835 6.20463C18.9835 6.20463 19.75 8.01687 19.75 10C19.75 10 19.75 11.9831 18.9835 13.7954C18.9835 13.7954 18.2434 15.5452 16.8943 16.8943C16.8943 16.8943 15.5452 18.2434 13.7954 18.9835C13.7954 18.9835 11.9831 19.75 10 19.75C10 19.75 8.01687 19.75 6.20463 18.9835C6.20463 18.9835 4.45478 18.2434 3.10571 16.8943C3.10571 16.8943 1.75663 15.5452 1.01651 13.7954C1.01651 13.7954 0.25 11.9831 0.25 10C0.25 10 0.25 8.01687 1.01651 6.20463C1.01651 6.20463 1.75663 4.45478 3.10571 3.10571C3.10571 3.10571 4.45478 1.75663 6.20463 1.01651C6.20463 1.01651 8.01687 0.25 10 0.25ZM10 1.75C10 1.75 8.32105 1.75 6.78896 2.39802C6.78896 2.39802 5.30857 3.02417 4.16637 4.16637C4.16637 4.16637 3.02417 5.30857 2.39802 6.78896C2.39802 6.78896 1.75 8.32104 1.75 10C1.75 10 1.75 11.679 2.39802 13.211C2.39802 13.211 3.02417 14.6914 4.16637 15.8336C4.16637 15.8336 5.30857 16.9758 6.78896 17.602C6.78896 17.602 8.32105 18.25 10 18.25C10 18.25 11.679 18.25 13.211 17.602C13.211 17.602 14.6914 16.9758 15.8336 15.8336C15.8336 15.8336 16.9758 14.6914 17.602 13.211C17.602 13.211 18.25 11.6789 18.25 10C18.25 10 18.25 8.32105 17.602 6.78896C17.602 6.78896 16.9758 5.30857 15.8336 4.16637C15.8336 4.16637 14.6914 3.02417 13.211 2.39802C13.211 2.39802 11.679 1.75 10 1.75Z'
        fill='#8A8A8A'
      />
    </svg>
  );
};

export default RadioButton;
