import { AddLineI, LineErrI, LineI, LineListErrI, LineListI } from '../Types/line';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addLine = async (data): Promise<AddLineI | LineErrI> => {
  return post(`/customer/line`, data).then((res) => res.data);
};
export const getSingleLine = async (data): Promise<LineI | LineErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/line/${id}`).then((res) => res.data);
};
export const getLines = async (
  data
): Promise<LineListI | LineListErrI | { lines: { results: [] } }> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/line`, payload).then((res) => res.data);
};
export const getFullLines = async () => {
  return get(`/customer/line/full-list`);
};
export const updateLine = async (data): Promise<AddLineI | LineErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/line/${id}`, data).then((res) => res.data);
};

export const deleteLine = async (id) => {
  return deleteRequest(`/customer/line/${id}`);
};
export const restoreLine = async (id) => {
  return patch(`/customer/line/restore/${id}`);
};
export const getSingleDeletedLine = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/line/deleted/${id}`);
};

export const hardDeleteLine = async (id) => {
  return deleteRequest(`/customer/line/hard-delete/${id}`);
};
export const lineApi = {
  addLine,
  getSingleLine,
  getLines,
  deleteLine,
  updateLine,
  getFullLines,
  restoreLine,
  hardDeleteLine,
  getSingleDeletedLine,
};
