import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { user } from '../queryKeys/userManagement';
import { useQueryClient } from '@tanstack/react-query';
import { cookie } from '../Utils/Cookies/cookie';
import { useVerifyToken } from '../hooks/authHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

const ComponentWrapper = () => {
  const queryClient = useQueryClient();
  const token = cookie.get('token');
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const location = useLocation();
  let isAuthenticated = false;
  if (token) {
    const { data: UserDetails } = useVerifyToken();
  }
  if (token) {
    isAuthenticated = true;
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (location.pathname.includes('add') || location.pathname.includes('edit')) &&
      userRole?.data?.success
    ) {
      const permission = userRole?.data?.permission;
      const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
      const currentEntity = location.pathname.split('/')?.[1]?.split('-');
      const moduleMapping = {
        machine: 'machine',
        manufacturing: 'workOrder',
        work: 'workOrder',
        process: 'process',
        part: 'part',
        product: 'product',
        mobile: 'mobile',
      };
      const rights = permission?.find(
        (role) => role?.userModule === moduleMapping?.[currentEntity?.[1]]
      );
      const isValid = rights?.[currentEntity?.[0]];
      if (!isValid && moduleMapping?.[currentEntity?.[1]] && !isAllPermission) {
        // Second condtion is for product, bcz view and edit product is on same page
        if (
          !(
            currentEntity?.[1] === 'product' &&
            currentEntity?.[0] === 'edit' &&
            rights?.view === true
          )
        ) {
          navigate(-1);
        }
      }
    }
  }, [userRole?.data?.success, location.pathname]);

  return (
    <React.Fragment>
      <Box display='flex'>
        <Sidebar isAuthenticated={isAuthenticated} user={userRole} />
        <Header isAuthenticated={isAuthenticated} />
      </Box>
    </React.Fragment>
  );
};
export default ComponentWrapper;
