import React from 'react';

const TicketInActiveIcon = () => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 15H15C15.4142 15 15.75 14.6642 15.75 14.25C15.75 13.8358 15.4142 13.5 15 13.5H9C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z'
          fill='#020202'
        />
        <path
          d='M9 12H15C15.4142 12 15.75 11.6642 15.75 11.25C15.75 10.8358 15.4142 10.5 15 10.5H9C8.58579 10.5 8.25 10.8358 8.25 11.25C8.25 11.6642 8.58579 12 9 12Z'
          fill='#020202'
        />
        <path
          d='M5.25 4.5H9C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3H5.25C4.62868 3 4.18934 3.43934 4.18934 3.43934C3.75 3.87868 3.75 4.5 3.75 4.5V20.25C3.75 20.8713 4.18934 21.3107 4.18934 21.3107C4.62868 21.75 5.25 21.75 5.25 21.75H18.75C19.3713 21.75 19.8107 21.3107 19.8107 21.3107C20.25 20.8713 20.25 20.25 20.25 20.25V4.5C20.25 3.87868 19.8107 3.43934 19.8107 3.43934C19.3713 3 18.75 3 18.75 3H15C14.5858 3 14.25 3.33579 14.25 3.75C14.25 4.16421 14.5858 4.5 15 4.5H18.75V20.25H5.25V4.5Z'
          fill='#020202'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.25 7.5C7.83579 7.5 7.5 7.16421 7.5 6.75V6C7.5 6 7.5 4.13604 8.81802 2.81802C8.81802 2.81802 10.136 1.5 12 1.5C12 1.5 13.864 1.5 15.182 2.81802C15.182 2.81802 16.5 4.13604 16.5 6V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5H8.25ZM14.1213 3.87868C14.1213 3.87868 15 4.75736 15 6H9C9 6 9 4.75736 9.87868 3.87868C9.87868 3.87868 10.7574 3 12 3C12 3 13.2426 3 14.1213 3.87868Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default TicketInActiveIcon;
