import { Box } from '@mui/material';
import React from 'react';

const MenuActiveIconArrow = () => {
  return (
    <Box pt={'3px'}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.4393 12L8.46967 18.9697C8.32902 19.1103 8.25 19.3011 8.25 19.5C8.25 19.6989 8.32902 19.8897 8.46967 20.0303C8.61032 20.171 8.80109 20.25 9 20.25C9.19891 20.25 9.38968 20.171 9.53033 20.0303L17.0303 12.5303C17.3232 12.2374 17.3232 11.7626 17.0303 11.4697L9.53033 3.96967C9.38968 3.82902 9.19891 3.75 9 3.75C8.80109 3.75 8.61032 3.82902 8.46967 3.96967C8.32902 4.11032 8.25 4.30109 8.25 4.5C8.25 4.69891 8.32902 4.88968 8.46967 5.03033L15.4393 12Z'
          fill='white'
        />
      </svg>
    </Box>
  );
};

export default MenuActiveIconArrow;
