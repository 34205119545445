import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useUpdateProfile } from '../../hooks/authHooks';
import { useUploadFile } from '../../hooks/uploadHook';
import { user } from '../../queryKeys/userManagement';
import { Avatar, styled } from '@mui/material';
import { Box } from '@mui/system';
const HeadBox = styled(Box)({
  '.avatar-size': {
    width: '50px',
    height: '50px',
  },
});
const ProfileAvatar = ({ handleOpen }: any) => {
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const userProfile: any = userData?.data?.user;
  const { mutate: uploadFile, data, isSuccess: isProfilePicUploadSuccess } = useUploadFile();
  const profilePic = data?.data?.data[0]?.location;
  const { mutate: updateProfile } = useUpdateProfile();

  React.useEffect(() => {
    if (isProfilePicUploadSuccess) {
      updateProfile({ id: userProfile?.id, profilePic: profilePic });
      handleOpen();
    }
  }, [isProfilePicUploadSuccess]);

  const handleFile = (e) => {
    const fileData = e.target.files[0];
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('fileName', fileData.name);
    formData.append('postfix', 'profilePic');
    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      return uploadFile(formData);
    };
  };

  return (
    <HeadBox>
      <label htmlFor='newFile'>
        <Avatar className='avatar-size' alt='' src={profilePic || userProfile?.profilePic}>
          {userProfile?.displayName?.[0]?.toUpperCase() || userProfile?.name?.[0]?.toUpperCase()}
        </Avatar>
        <input onChange={(e) => handleFile(e)} type='file' id='newFile' hidden />
      </label>
    </HeadBox>
  );
};

export default ProfileAvatar;
