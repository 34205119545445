import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Plus from '../../assets/svg/Plus';

const CustomDivider = styled(Divider)(() => ({
  borderColor: '#F6F6F6 !important',
  margin: '11px 0px 12px 0px !important',
}));
const HeadBox = styled(Box)(() => ({
  '.customized-button': {
    width: 'auto',
    marginLeft: '14px',
    background: '#0160B9',
    borderRadius: '4px',
    fontWeight: 600,
    height: '40px',
  },
  '.margin-left': {
    marginLeft: '10px',
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    border: '1px solid #D2D1D1',
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxSizing: 'border-box',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '10px',
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-selected': {
        backgroundColor: '#e44444',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const CustomizeMenuItem = styled(MenuItem)(() => ({
  width: '290px',
  color: '#0160B9',
  fontSize: '14px',
  lineHeight: '28px',
  fontWeight: '600 !important',
  padding: '0px',
  marginLeft: '11px',
}));

export default function ButtonComponent(props: {
  title?: string;
  isBtnDisable?: boolean;
  linkData?: { menuItem: string; to: string; requestCount?: number }[];
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <HeadBox>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        disabled={props?.isBtnDisable}
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        className='customized-button'
      >
        <Plus fill='white' />
        <span className='margin-left'>{props.title}</span>
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props?.linkData?.map((link, ind: number) => {
          return (
            <Box
              key={ind}
              onClick={() => navigate(link.to, { state: { requestCount: link?.requestCount } })}
            >
              <CustomizeMenuItem onClick={handleClose} disableRipple>
                {link.menuItem}
              </CustomizeMenuItem>
              {props?.linkData && ind !== props?.linkData?.length - 1 && <CustomDivider />}
            </Box>
          );
        })}
      </StyledMenu>
    </HeadBox>
  );
}
