import React from 'react';

const SubMenuItemIcon = () => {
  return (
    <div>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.8661 10L7.05806 15.8081C6.94085 15.9253 6.875 16.0842 6.875 16.25C6.875 16.4158 6.94085 16.5747 7.05806 16.6919C7.17527 16.8092 7.33424 16.875 7.5 16.875C7.66576 16.875 7.82473 16.8092 7.94194 16.6919L14.1919 10.4419C14.436 10.1979 14.436 9.80214 14.1919 9.55806L7.94194 3.30806C7.82473 3.19085 7.66576 3.125 7.5 3.125C7.33424 3.125 7.17527 3.19085 7.05806 3.30806C6.94085 3.42527 6.875 3.58424 6.875 3.75C6.875 3.91576 6.94085 4.07473 7.05806 4.19194L12.8661 10Z'
          fill='#020202'
        />
      </svg>
    </div>
  );
};

export default SubMenuItemIcon;
