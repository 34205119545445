import { get, patch } from '../Utils/index';

export const getUserNotifications = async (data) => {
  const days = data?.queryKey[1];
  return get(`/customer/user-notifications/notification-list`, { ...(days && { days }) });
};

export const readNotification = async (id) => {
  return patch(`/customer/user-notifications/read/${id}`);
};

export const notificationApi = {
  getUserNotifications,
  readNotification,
};
