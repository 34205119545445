import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import {
  useDeleteLine,
  useGetDeletedLine,
  useGetLine,
  useHardDeleteLine,
  useRestoreLine,
} from '../../hooks/lineHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import './../../assets/css/detailsInformationBox.css';

const HeadBoxWrapper = styled(Box)(() => ({
  paddingBottom: '40px',
}));
const TextFiled = styled(Typography)(() => ({
  color: '#0160B9',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const LineDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  // const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: lineDetails, isSuccess, isError } = useGetLine(id, !!id && !isDeleted);
  const { data: getLineDeleted, isSuccess: isSuccessDeleted } = useGetDeletedLine(
    id,
    !!id && isDeleted
  );
  const singleLine = isDeleted ? getLineDeleted?.line : lineDetails;
  const { mutate: deleteSingleLine } = useDeleteLine();
  const { mutate: hardDeleteLine } = useHardDeleteLine();
  const { mutate: restoreLine } = useRestoreLine();
  const { mutate: deleteLine } = useDeleteLine();
  const [isDeleteEnable, setIsDeleteEnable] = React.useState(false);

  const workerList = lineDetails?.users;
  const machineList = lineDetails?.machines;
  // const handleDeleteClick = () => {
  //   deleteLine(id);
  // };
  const handleLineDelete = () => {
    if (isDeleted) {
      hardDeleteLine(id);
    } else {
      deleteSingleLine(id);
    }
  };

  const handleLineRestore = () => {
    if (isRestore) {
      restoreLine(id);
    } else {
      // nothing to restore
    }
  };
  React.useEffect(() => {
    if (machineList?.length || workerList?.length) {
      setIsDeleteEnable(true);
    }
  }, [machineList, workerList]);

  const breadCrumbData = [
    {
      title: 'Factory Layout',
      link: '/factory-layout',
      activeTab: '1',
    },
    {
      title: _.get(singleLine, 'name', ''),
      link: `/line-details/${id}`,
    },
  ];

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-line/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  return (
    <React.Fragment>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleLine, 'name', '')}
          backToLink='/factory-layout'
          isDetailPage={true}
          activeTab='1'
          detailPageData={detailPageData}
          handleDelete={handleLineDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handleLineRestore}
          isDeleteEnable={machineList?.length || workerList?.length ? false : true}
          errMsg={`You can not able to delete this line, this line is assigned to many ${
            'Machine' && 'workers'
          }`}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  List of machines
                </Typography>
                <Grid container spacing={3}>
                  {machineList?.map((machine: string, ind: number) => {
                    return (
                      <Grid item md={6} key={ind}>
                        <TextFiled>{machine}</TextFiled>
                      </Grid>
                    );
                  })}
                  {!machineList?.length && (
                    <Grid item>
                      <TextFiled>No Machine Found</TextFiled>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  List of Workers
                </Typography>
                <Grid container spacing={3}>
                  {workerList?.map((worker: string, ind: number) => {
                    return (
                      <Grid item md={6} key={ind}>
                        <TextFiled> {worker}</TextFiled>
                      </Grid>
                    );
                  })}
                  {!workerList?.length && (
                    <Grid item>
                      <TextFiled>No Worker Found</TextFiled>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loading
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWrapper>
    </React.Fragment>
  );
};
export default LineDetails;
