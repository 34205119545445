import { Box } from '@mui/material';
import React from 'react';

const RadioButtonFilled = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2.25C12 2.25 13.9831 2.25 15.7954 3.01651C15.7954 3.01651 17.5452 3.75663 18.8943 5.10571C18.8943 5.10571 20.2434 6.45478 20.9835 8.20463C20.9835 8.20463 21.75 10.0169 21.75 12C21.75 12 21.75 13.9831 20.9835 15.7954C20.9835 15.7954 20.2434 17.5452 18.8943 18.8943C18.8943 18.8943 17.5452 20.2434 15.7954 20.9835C15.7954 20.9835 13.9831 21.75 12 21.75C12 21.75 10.0169 21.75 8.20463 20.9835C8.20463 20.9835 6.45478 20.2434 5.10571 18.8943C5.10571 18.8943 3.75663 17.5452 3.01651 15.7954C3.01651 15.7954 2.25 13.9831 2.25 12C2.25 12 2.25 10.0169 3.01651 8.20463C3.01651 8.20463 3.75663 6.45478 5.10571 5.10571C5.10571 5.10571 6.45478 3.75663 8.20463 3.01651C8.20463 3.01651 10.0169 2.25 12 2.25ZM12 3.75C12 3.75 10.321 3.75 8.78896 4.39802C8.78896 4.39802 7.30857 5.02417 6.16637 6.16637C6.16637 6.16637 5.02417 7.30857 4.39802 8.78896C4.39802 8.78896 3.75 10.321 3.75 12C3.75 12 3.75 13.679 4.39802 15.211C4.39802 15.211 5.02417 16.6914 6.16637 17.8336C6.16637 17.8336 7.30857 18.9758 8.78896 19.602C8.78896 19.602 10.321 20.25 12 20.25C12 20.25 13.679 20.25 15.211 19.602C15.211 19.602 16.6914 18.9758 17.8336 17.8336C17.8336 17.8336 18.9758 16.6914 19.602 15.211C19.602 15.211 20.25 13.6789 20.25 12C20.25 12 20.25 10.3211 19.602 8.78896C19.602 8.78896 18.9758 7.30857 17.8336 6.16637C17.8336 6.16637 16.6914 5.02417 15.211 4.39802C15.211 4.39802 13.679 3.75 12 3.75Z'
        fill='#0160B9'
      />
      <circle cx='12' cy='12' r='6' fill='#0160B9' />
    </svg>
  );
};

export default RadioButtonFilled;
