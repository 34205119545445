const CheckboxIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 4.5C3 4.5 3 3.87868 3.43934 3.43934C3.43934 3.43934 3.87868 3 4.5 3H19.5C19.5 3 20.1213 3 20.5607 3.43934C20.5607 3.43934 21 3.87868 21 4.5V19.5C21 19.5 21 20.1213 20.5607 20.5607C20.5607 20.5607 20.1213 21 19.5 21H4.5C4.5 21 3.87868 21 3.43934 20.5607C3.43934 20.5607 3 20.1213 3 19.5V4.5ZM4.5 4.5V19.5H19.5V4.5H4.5Z'
        fill='#1C1C1C'
      />
    </svg>
  );
};

export default CheckboxIcon;
