import React from 'react';

const ChecklistActiveIcon = () => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 12.75H20.25C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25H12C11.5858 11.25 11.25 11.5858 11.25 12C11.25 12.4142 11.5858 12.75 12 12.75Z'
          fill='white'
        />
        <path
          d='M12 6.75H20.25C20.6642 6.75 21 6.41421 21 6C21 5.58579 20.6642 5.25 20.25 5.25H12C11.5858 5.25 11.25 5.58579 11.25 6C11.25 6.41421 11.5858 6.75 12 6.75Z'
          fill='white'
        />
        <path
          d='M12 18.75H20.25C20.6642 18.75 21 18.4142 21 18C21 17.5858 20.6642 17.25 20.25 17.25H12C11.5858 17.25 11.25 17.5858 11.25 18C11.25 18.4142 11.5858 18.75 12 18.75Z'
          fill='white'
        />
        <path
          d='M9.13323 5.05155C9.14067 5.04469 9.14818 5.03748 9.15533 5.03033C9.28856 4.8971 9.36677 4.7186 9.37439 4.53033L9.37443 4.52923C9.37481 4.51949 9.375 4.50974 9.375 4.5C9.375 4.31158 9.30408 4.13007 9.17635 3.99155C9.16949 3.98412 9.16248 3.97682 9.15533 3.96967C9.0221 3.83644 8.8436 3.75823 8.65533 3.75061L8.65423 3.75057C8.64449 3.75019 8.63474 3.75 8.625 3.75C8.43658 3.75 8.25507 3.82092 8.11655 3.94865L5.37261 6.47909L4.25924 5.44939C4.12064 5.3212 3.93879 5.25 3.74999 5.25C3.74024 5.25 3.73049 5.25019 3.72074 5.25057C3.52198 5.25833 3.33444 5.34473 3.19939 5.49076C3.0712 5.62936 3 5.81121 3 6C3 6.00976 3.00019 6.01951 3.00057 6.02926C3.00833 6.22802 3.09473 6.41556 3.24076 6.55061L4.86264 8.05061C5.14978 8.31618 5.5928 8.3165 5.88032 8.05135L9.13323 5.05155Z'
          fill='white'
        />
        <path
          d='M9.13323 11.0515C9.14067 11.0447 9.14818 11.0375 9.15533 11.0303C9.28856 10.8971 9.36677 10.7186 9.37439 10.5303L9.37443 10.5292C9.37481 10.5195 9.375 10.5097 9.375 10.5C9.375 10.3116 9.30408 10.1301 9.17635 9.99155C9.16949 9.98412 9.16248 9.97682 9.15533 9.96967C9.0221 9.83644 8.8436 9.75823 8.65533 9.75061L8.65423 9.75057C8.64449 9.75019 8.63474 9.75 8.625 9.75C8.43658 9.75 8.25507 9.82092 8.11655 9.94865L5.37261 12.4791L4.25924 11.4494C4.12064 11.3212 3.93879 11.25 3.74999 11.25C3.74024 11.25 3.73049 11.2502 3.72074 11.2506C3.52198 11.2583 3.33444 11.3447 3.19939 11.4908C3.0712 11.6294 3 11.8112 3 12C3 12.0098 3.00019 12.0195 3.00057 12.0293C3.00833 12.228 3.09473 12.4156 3.24076 12.5506L4.86264 14.0506C5.14978 14.3162 5.5928 14.3165 5.88032 14.0513L9.13323 11.0515Z'
          fill='white'
        />
        <path
          d='M9.13323 17.0515C9.14067 17.0447 9.14818 17.0375 9.15533 17.0303C9.28856 16.8971 9.36677 16.7186 9.37439 16.5303L9.37443 16.5292C9.37481 16.5195 9.375 16.5097 9.375 16.5C9.375 16.3116 9.30408 16.1301 9.17635 15.9916C9.16949 15.9841 9.16248 15.9768 9.15533 15.9697C9.0221 15.8364 8.8436 15.7582 8.65533 15.7506L8.65423 15.7506C8.64449 15.7502 8.63474 15.75 8.625 15.75C8.43658 15.75 8.25507 15.8209 8.11655 15.9487L5.37261 18.4791L4.25924 17.4494C4.12064 17.3212 3.93879 17.25 3.74999 17.25C3.74024 17.25 3.73049 17.2502 3.72074 17.2506C3.52198 17.2583 3.33444 17.3447 3.19939 17.4908C3.0712 17.6294 3 17.8112 3 18C3 18.0098 3.00019 18.0195 3.00057 18.0293C3.00833 18.228 3.09473 18.4156 3.24076 18.5506L4.86264 20.0506C5.14978 20.3162 5.5928 20.3165 5.88032 20.0513L9.13323 17.0515Z'
          fill='white'
        />
      </svg>
    </div>
  );
};

export default ChecklistActiveIcon;
