//this is use for mobile
import { AddUserRoleI, UserRoleErr } from '../Types/userRole';
import { deleteRequest, get, patch, post } from '../Utils/index';

export const getSingleRole = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/user-role/${id}`);
};

export const addRole = async (data): Promise<AddUserRoleI | UserRoleErr> => {
  return post(`/customer/user-role`, data).then((res) => res.data);
};
export const updateRole = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/user-role/${id}`, data);
};
export const deleteRole = async (id) => {
  return deleteRequest(`/customer/user-role/${id}`);
};
export const restoreRole = async (id) => {
  return patch(`/customer/user-role/restore/${id}`);
};
export const getSingleDeletedRole = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/user-role/deleted/${id}`);
};

export const hardDeleteRole = async (id) => {
  return deleteRequest(`/customer/user-role/hard-delete/${id}`);
};

export const getRoles = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/user-role`, payload);
};
export const getFullRoles = async () => {
  return get(`/customer/user-role/full-list`);
};
export const userRoleAPI = {
  deleteRole,
  getSingleRole,
  updateRole,
  getRoles,
  addRole,
  restoreRole,
  hardDeleteRole,
  getSingleDeletedRole,
  getFullRoles,
};
