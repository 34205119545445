import { Box } from '@mui/material';
import React from 'react';

const RestoreAll = () => {
  return (
    <Box pt={1} pr={1}>
      <svg
        width='15'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.82043 10.9453V6.57031C2.82043 6.22513 2.54061 5.94531 2.19543 5.94531C1.85026 5.94531 1.57043 6.22513 1.57043 6.57031V11.5703C1.57043 11.9155 1.85026 12.1953 2.19543 12.1953H7.19543C7.54061 12.1953 7.82043 11.9155 7.82043 11.5703C7.82043 11.2251 7.54061 10.9453 7.19543 10.9453H2.82043Z'
          fill='#0160B9'
        />
        <path
          d='M4.25349 8.62842L1.75349 11.1284C1.63628 11.2456 1.57043 11.4046 1.57043 11.5703C1.57043 11.7361 1.63628 11.8951 1.75349 12.0123C1.8707 12.1295 2.02967 12.1953 2.19543 12.1953C2.36119 12.1953 2.52017 12.1295 2.63738 12.0123L5.13738 9.5123C6.6122 8.03671 8.65818 7.62952 8.65818 7.62952C10.7042 7.22233 12.6314 8.02075 12.6314 8.02075C14.5587 8.81917 15.7173 10.5539 15.7173 10.5539C16.8759 12.2887 16.8751 14.3748 16.8751 14.3748C16.8751 14.4129 16.8786 14.4511 16.8855 14.4885C16.9402 14.7849 17.1986 14.9999 17.4999 15C17.5381 15 17.5762 14.9966 17.6136 14.9897C17.9099 14.9349 18.125 14.6766 18.1251 14.3753C18.126 11.91 16.7568 9.8597 16.7568 9.8597C15.3875 7.80951 13.1098 6.86592 13.1098 6.86592C10.8322 5.92233 8.41419 6.40356 8.41419 6.40356C5.99622 6.88478 4.25349 8.62842 4.25349 8.62842Z'
          fill='#0160B9'
        />
      </svg>
    </Box>
  );
};

export default RestoreAll;
