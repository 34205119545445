import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useParams } from 'react-router-dom';
import { useDeleteFactorySchedule, useGetFactorySchedule } from '../../hooks/factoryScheduleHooks';
import Loading from 'react-fullscreen-loading';
import { getEndTime, getStartTime } from '../../Utils/convertTime';
import './../../assets/css/detailsInformationBox.css';
import moment from 'moment';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.ml-24': {
    padding: '0px 0px 24px 24px !important',
  },
}));

const FactoryScheduleDetails = () => {
  const { id } = useParams();
  const { data: getSingleSchedule, isSuccess, isError } = useGetFactorySchedule();
  const getSchedule = getSingleSchedule;
  const newWorkingDays = getSchedule?.workingDays.join(', ');
  const { mutate: deleteSchedule } = useDeleteFactorySchedule();

  const handleDelete = () => {
    deleteSchedule(id);
  };
  const breadCrumbData = [
    {
      title: 'Factory Schedule',
      link: '',
    },
    {
      title: `Factory Schedule`,
      link: ``,
    },
    // {
    //   title: `Factory Schedule`,
    //   link: ``,
    // },
  ];

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-factory-schedule/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`Factory Schedule`}
        backToLink='/factory-schedule'
        isDetailPage={true}
        detailPageData={detailPageData}
        handleDelete={handleDelete}
        hideDelete={true}
      />

      <HeadBoxWrapper className='main-padding'>
        <Box className='details-box-border padding-bottom margin-bottom'>
          <Typography id='modal-modal-title' className='details-sub-title'>
            Factory Timing
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextFiled>Working Days</TextFiled>
              <Typography className='span-color pl-sub-text'>{newWorkingDays}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextFiled>Start Time </TextFiled>
              <Typography className='span-color pl-sub-text'>
                {getStartTime(getSchedule?.startTime)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextFiled>End Time </TextFiled>
              <Typography className='span-color pl-sub-text'>
                {getEndTime(getSchedule?.endTime)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* <Divider /> */}
        <Box className='details-box-border padding-bottom margin-bottom'>
          <Typography id='modal-modal-title' className='details-sub-title'>
            Shifts
          </Typography>
          {getSchedule?.shifts?.map((data, ind) => {
            return (
              <Grid container spacing={0} key={ind}>
                <Grid item xs={4} md={4} lg={4}>
                  <TextFiled>Title </TextFiled>
                  <Typography id='modal-modal-title' className='span-color pl-sub-text'>
                    {data?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <TextFiled>Start time </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {getStartTime(data?.startTime)}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <TextFiled>End time</TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {getEndTime(data?.endTime)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Box>
        {/* <Divider /> */}
        <Box className='details-box-border padding-bottom'>
          <Typography id='modal-modal-title' className='details-sub-title'>
            Holidays
          </Typography>

          <Grid container spacing={0}>
            {getSchedule?.holidays?.map((data, ind) => {
              return (
                <Grid item xs={4} md={4} lg={4} key={ind}>
                  <TextFiled>{data?.name}</TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {moment(data?.date).format('Do MMMM, YYYY')}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {/* <Divider /> */}

        <Loading
          loading={id && !isSuccess ? (!isError ? true : false) : false}
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWrapper>
    </React.Fragment>
  );
};
export default FactoryScheduleDetails;
