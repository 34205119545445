import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { processApi } from '../api/process';
import { process } from '../queryKeys/processes';
import { ProcessErrI, ProcessListErrI } from '../Types/process';

export const useGetProcess = (id, isEnabled = true) => {
  return useQuery([process.PROCESS, id], processApi.getSingleProcess, {
    enabled: isEnabled,
    // onSuccess: (res: ProcessI) => {
    //   // const data = res?.data;
    // },
    onError: (err: ProcessErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetDeletedProcess = (id, isEnabled = true) => {
  return useQuery([process.PROCESS + 'DELETED', id], processApi.getSingleDeletedProcess, {
    select: (data) => data?.data,
    enabled: isEnabled,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    onError: (err: ProcessErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetProcesses = (isEnabled = true, page) => {
  return useQuery([process.PROCESSES, page], processApi.getProcesses, {
    initialData: { processes: { results: [] } },
    enabled: isEnabled,
    select: (data) => data?.processes,
    // onSuccess: (res: ProcessListI) => {
    //   const data = res?.data;
    // },
    onError: (err: ProcessListErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useFullGetProcesses = (isEnabled = true) => {
  return useQuery([process.PROCESSES + 'FULL'], processApi.getFullProcesses, {
    initialData: { processes: [] },
    enabled: isEnabled,
    select: (data) => data?.processes,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddProcess = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(processApi.addProcess, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([process.PROCESSES]);
      const data = res.data;
      // navigate(`/process-details/${data?.process?.id}`);
      navigate(`/processes`);
    },
    onError: (err: ProcessErrI) => {
      return err;
      // const errData = err?.data;
      //   queryClient.invalidateQueries(['user']);
      //   navigate('/login');
      // toast.error(errData.message);
    },
  });
};

export const useUpdateProcess = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(processApi.updateProcess, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([process.PROCESSES]);
      queryClient.invalidateQueries([process.PROCESS, id]);
      // navigate(`/process-details/${data?.process?.id}`);
      navigate(`/processes`);
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteProcess = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(processApi.deleteProcess, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([process.PROCESSES]);
      queryClient.removeQueries([process.PROCESS, id]);
      navigate(`/processes`);
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};

export const useRestoreProcess = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(processApi.restoreProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries([process.PROCESS]);
      navigate('/deleted-items');
    },
    onError: () => {
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useHardDeleteProcess = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(processApi.hardDeleteProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries([process.PROCESS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    onError: () => {
      //toast.error(errData.message);
    },
  });
};
